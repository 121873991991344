<template>
  <div>
    <img v-if="getModuleConfig('page_kv_image')" :src="getModuleConfig('page_kv_image')" class="w-100" />
    <div v-if="showPage" class="page">
      <div v-if="getModuleConfig('intro_page.form_title')" class="page__title">
        {{ getModuleConfig("intro_page.form_title") }}
      </div>
      <div v-if="getModuleConfig('intro_page.form_desc')" class="page__desc">
        {{ getModuleConfig("intro_page.form_desc") }}
      </div>

      <div v-if="content" v-html="content"></div>
    </div>

    <img
      v-for="image in contentImages"
      :src="image.image_url"
      :key="image.order"
      class="w-100"
    />

    <div class="page__button s-space-y-4">
      <MemberButton
        :button-text="getModuleConfig('intro_page.button_text')"
        :button-url="getModuleConfig('intro_page.button_url')"
        :type="getModuleConfig('intro_page.button_action')"
      ></MemberButton>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import registerMixin from "@/mixins/liff/register";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";

export default {
  mixins: [registerMixin],
  components: { MemberButton },
  data() {
    return {
      content: null,
    };
  },
  computed: {
    contentImages() {
      let contentImages = this.getModuleConfig("intro_page.content_images") || []
      contentImages.sort((a, b) => a.order - b.order)
      return contentImages
    },
    showPage() {
      return this.getModuleConfig('intro_page.form_title')
        || this.getModuleConfig('intro_page.form_desc')
        || this.content
    }
  },
  beforeMount() {
    this.fetchContent();
  },
  methods: {
    async fetchContent() {
      const contentUrl = this.getModuleConfig("intro_page.content_url")
      if (!contentUrl) return
      let response = await axios.get(contentUrl);
      this.content = response.data;
    },
  },
};
</script>
